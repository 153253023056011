.App .ExercisesList {
    background-image: url(../../../common/images/backgroundImageWorkouts2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.WorkoutsList .containerTop {
    display: flex;
    flex-direction: row;
    margin: 5px 2%;
    justify-content: center;
}

.containerTop .button {
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 3px 10px;
    width: 180px;
    border-radius: 35px;
    text-align: center;
}

.button.One {
    background-color: rgb(137, 137, 138);
    margin-right: 20px;
}

.button.Two {
    background-color: rgb(72, 163, 248);
}

.WorkoutsList .containerBottom {
    display: flex;
    flex-direction: column;
    margin: 10px 2%;
}

.WorkoutsList .list {
    position: relative;  
    height: 90px;  
}

.list {
    position: absolute;
    margin-bottom: 12px;
    box-shadow: 1px 1px 6px silver;  
}

.list img {
    height: 90px;
}

.WorkoutsList .list .text {
    text-shadow: 3px 2px 10px rgb(8, 8, 8);
    color: white;
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    top: 20%;
    left: 2%;
}

@media (min-width: 600px) {
    .WorkoutsList .containerBottom {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 60px;
        margin: 30px 2%;
    }
    
    .WorkoutsList .list {
        position: relative;  
        height: 120px;     
    }
    
    .list {
        position: absolute;
        margin-bottom: 40px;
    }
    
    .list img {
        height: 120px;
    }
}

