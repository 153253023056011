.NavBarButton {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 70px;
  align-items: center;
  justify-content: center;
  color: #333;
  background-color: #eee;
  font-size: 0.9em;
}

.NavBarButton.active {
  color: #1779ba;
}

.NavBarButton .icon svg {
  fill: #333;
  font-size: 1.5em;
}

.NavBarButton.active .icon svg {
  fill: #1779ba;
}