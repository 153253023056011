.Tracker .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.Tracker  {
   width: 90%;
   margin-left: 10px;
}

.grid-full {
    grid-column: 1/3;
    position: relative;
}

.margin-bottom {
    height: 20vw;
}

.center {
    justify-self: center;
}

.position {
    position: absolute;
    top: 10px;
    left: 20px;
}
.blue-box {
    background-color: #00AAEF;
    height: 24px;
    width: 31px;
    display: inline-block;
    top: 4px;
    position: relative;
    left: 10px;
}

@media only screen and (min-width: 700px) {
      .position {
        position: absolute;
        top: 70px;
        left: 29px;
      }

}