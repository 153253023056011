.SelectGender {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--kodfit-blue);
}

.SelectGender select {
    font-size: 16px;
    font-weight: 700;
    padding-left: 20px;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
}
