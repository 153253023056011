.InputField {
    border-bottom: 1px var(--kodfit-blue) solid;
    display: flex;
    justify-content: space-between;
}

.InputField input {
    width: 50px;
    font-size: 16px;
    text-align: center;
    border: 0;
}
