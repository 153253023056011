.HeroBanner {
    position: relative;
}

.HeroBanner .hero-overlay {
    left: 50%;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.HeroBanner .hero-overlay h1 {
    color: #fff;
    font-size: 9vw;
}

.HeroBanner img {
    object-fit: cover;
}

.HeroBanner .hero-image {
    display: block;
    z-index: -1;
}

.HeroBanner .btn {
    height: 46px;
    padding: 0;
    width: 185px;
}

.HeroBanner .btn a {
    color: #fff;
    padding: 0.9rem 2.7rem;
}

.HeroBanner .btn-transparent {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 800;
    line-height: 1.45;
    text-transform: uppercase;
}

.HeroBanner .rounded-capsule {
    border-radius: 3rem;
}