.resetst-btn {
    margin: 8px 20px;
    font-size:20px;
    border-radius: 40px;
    padding: 15px 16px 10px 16px;
}

.smalltimer-btn {
    margin: 8px 4px;
    font-size:20px;
    border-radius: 40px;
    padding: 15px 16px 10px 16px;
}

.SmallTimer {
   display: grid; 
   grid-template-columns: 1fr 1fr;
   height: 100px;
}     

.full-display {
    grid-column: 1/3;
    justify-self: center;
}