.List .content {
  display: grid;
  overflow: scroll;
  padding-left: 0;
  height: 350px;
}

.List .item {
  align-items: center;
  box-shadow: 2px 2px 2px silver;
  margin-bottom: 8px;
}