* {
    box-sizing: border-box;
  }
  *::before, *::after {
    box-sizing: border-box;
  }

html {
    font-size: 100%;
}

body {
    background: #fefefe;
    line-height: 1.5;
    color: #0a0a0a;
}

img {
    height: auto;
    width: 100%;
}


.container {
    position: relative;
    margin: auto;
    max-width: 71rem;
    padding: 2rem 1rem 0;
    width: 100%;
}

.grid {
    display: flex;
    flex-direction: column;
    /* flex-flow: row wrap; */
}