.Login button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
}

.Login input {
    padding: 10px;
    border: 0;
    border-bottom: 1px solid;
}

.Login .logoBox {
    margin: 50px;
    display: flex;
    justify-content: center;
}

.Login .logo {
    width: 150px;
}

.Login .container {
    display: grid;
}

.Login .inputBox-content {
    margin: 10px;
    display: flex;
    justify-content: center;
}

.Login .LoginButtons {
    display: grid;
    justify-content: center;
    gap: 10px;
}

.Login .LoginButtons > * {
    text-transform: uppercase;
    text-align: center;
    font-family: 'Play', sans-serif;
}

.Login .signInButton {
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 0rem;
}

.Login .btn-google {
    color: #00AAEF;
}
