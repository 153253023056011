.Timer {
    display: flex;
    justify-content: center;
}

.Timer .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px 6px;
}

.Timer .display-timer {
    grid-column: 1 / 4;
    justify-self: center;
    font-size: 78px;
}

.Timer input {
    width: 37px;
}

.Timer span {
    padding-right: 5px;
}

.Timer .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 34px;
}


.Timer .inputs {
    display: flex;
    grid-column: 1/4;
    justify-content: center;
}

.Timer input {
    width: 98px;
    height: 62px;
    font-size: 65px;
    text-align: center;
    padding-left: 6px;
}

.Timer .center {
    justify-self: center;
}

.Timer .buttons {
    grid-column: 1 / 4;
}

.Timer .inline {
    display: inline-block;
    margin: 12px 21px;
}

.Timer .time-buttons  {
    width: 70px;
    height: 66px;
    border-radius: 134px;
    font-size: 38px;
    padding-top: 12px;
    padding-left: 7px;
}

.Timer .titles {
    font-size: 42px;
    grid-column: 1 / 4;
    justify-self: center;
}

.Timer .titles span {
    margin:10px;
}

.Timer .active {
    background: #00AAEF;
    border-radius: 34px;
    height: 334px;
    font-size: 38px;
    padding: 10px 17px 16px 25px;
    color: white;
}
.Timer span a {
     color: black;
}

@media only screen and (max-width: 640px) {
    .Timer .display-timer {
        font-size: 16vw;
        margin: 20px 0px;
    }
    
    .Timer .titles, 
    .Timer .active {
        font-size: 24px;
    }

    .Timer input {
        width: 98px;
        font-size: 58px;
        margin: 2px 4px;
    }

    .Timer .column {
        margin: 10px 2%;
    }

    .Timer .display-timer {
        font-size: 16vw;
    }

    .Timer .grid-container {
        width: 100%;
    }

    .Timer {
        margin: 20px;
    }
}