
h1,
h2,
h3,
h4,
h5 {
    line-height: 1.4;
    font-weight: normal;
    color: #222222;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
}

h1 {
    font-size: 2.5rem;
}

@media (min-width: 600px) {
    h1 {
        font-size: 2.44444rem;
    }
}

h2 {
    font-size: 1.5rem;
}

@media (min-width: 600px) {
    h2 {
        font-size: 2.05556rem;
    }
}

h3 {
    font-size: 1.22222rem;
}

@media (min-width: 600px) {
    h3 {
        font-size: 1.5rem;
    }
}

h4 {
    font-size: 1rem;
}

@media (min-width: 600px) {
    h4 {
        font-size: 1.27778rem;
    }
}

h5 {
    font-size: 1rem;
}

a {
    color: #00AAEF;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
}

a:hover,
a:focus {
    color: #0094d0;
}

a:hover,
a:focus {
    outline-width: 0;
}

p {
    font-family: inherit;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.6;
    margin-bottom: 1.11111rem;
    text-rendering: optimizeLegibility;
}

p:last-child {
    margin-bottom: 0;
}

blockquote {
    line-height: 1.6;
    color: #6f6f6f;
    margin: 0 0 1.11111rem;
    padding: 0.5rem 1.11111rem 0 1.05556rem;
    border-left: 1px solid #DDDDDD;
}