.PersonalInfo {
    padding: 1rem 0;
    display: flex;
    justify-content: space-evenly;
}

.PersonalInfo .value {
    font-size: 20px;
    font-weight: bold;
}

@media (min-width: 600px) {
    .PersonalInfo {
        width: 700px;
        margin: auto;
    }
}
