.containerBottom {
    display: flex;
    flex-direction: column;
    margin: 10px 2%;
}

.routine {
    position: relative;
    margin-bottom: 12px;
    box-shadow: 1px 1px 6px silver; 
    height: 90px;     
}

.routine img {
    height: 90px;
}

.routine .text {
    text-shadow: 3px 2px 10px rgb(8, 8, 8);
    color: white;
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    top: 20%;
    left: 2%;
}


@media (min-width: 600px) {
    .containerBottom {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 30px 2%;
    }
    .routine {
        position: relative;
        margin-bottom: 40px;
        box-shadow: 1px 1px 6px silver; 
        height: 120px;     
    }
    .routine img {
        height: 120px;
    }
}