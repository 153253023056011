html {
    --kodfit-blue: #00AAEF;   
}

.text-center {
    text-align: center;
}

.block {
    display: block;
}


.separator-left {
    text-align: left;   
}
.separator-left:after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #00AAEF;
    margin: 1rem auto 0;
    margin-left: 0 !important;
}
.separator-left:before, 
.separator-left:after {
    display: table;
    content: ' ';
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}

.separator-center {
    text-align: center;
}
.separator-center:after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #00AAEF;
    margin: 1rem auto 0;
}
.separator-center:before, 
.separator-center:after {
    display: table;
    content: ' ';
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}

.separator-right {
    text-align: right;
}
.separator-right:after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #00AAEF;
    margin: 1rem auto 0;
    margin-right: 0 !important;
}
.separator-right:before, 
.separator-right:after {
    display: table;
    content: ' ';
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}