.AboutCard {
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  text-align: justify;
  background-color: #eee;
  margin: 20px 15px;
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.15);
}

.AboutCard .title{
  font-size: 1.5em;
  font-weight: 600;
}

.AboutCard .icon svg {
  fill: #333;
  font-size: 5em;
  padding: 15px
}

.AboutCard .icon {
  margin: 10px;
  border: 2px solid #333;
  border-radius: 200px;
}