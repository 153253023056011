a.button:hover,
a.button:focus {
    text-decoration: none;
}

.button {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem;
    padding: 0.85em 1em;
    border: 1px solid transparent;
    border-radius: 0;
    -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 0.9rem;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
}

.button.primary {
    background-color: #00AAEF;
}

.button.primary a {
    color: #fefefe;
}

.button.primary:hover,
.button.primary:focus {
    background-color: #0094d0;
}

.button.secondary {
    background-color: #767676;
}

.button.secondary a {
    color: #fefefe;
}

.button.secondary:hover,
.button.secondary:focus {
    background-color: #5e5e5e;
}

.button.success {
    background-color: #3adb76;
}

.button.success a {
    color: #0a0a0a;
}

.button.success:hover,
.button.success:focus {
    background-color: #22bb5b;
}

.button.alert {
    background-color: #cc4b37;
}

.button.alert a {
    color: #fefefe;
}

.button.alert:hover,
.button.alert:focus {
    background-color: #a53b2a;
}

.button.btn-transparent {
    background-color: transparent;
    border: 1px solid #00AAEF;
}

.button.btn-transparent a {
    color: #00AAEF;
}

.button.btn-transparent:hover,
.button.btn-transparent:focus {
    background-color: #00AAEF;
    color: #ffffff;
}

.button.btn-transparent:hover a,
.button.btn-transparent:focus a {
    color: #ffffff;
}

.button.rounded-capsule {
    border-radius: 2rem;
}

