.NearMe {   
    display: grid;
}

.NearMe .map {
    height: 300px;
}

.NearMe .switch-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-top: 15px;
}

@media (min-width: 600px) {
    .NearMe {
        grid-template-columns: 2fr 1fr;
    }

    .NearMe .switch-buttons {
        margin-top: 0;
    }

    .NearMe .map {
        height: 500px;
    }

    .NearMe .buttons-and-list {
        padding: 0 10px;
    }
}
