.containerTop  {
    display: flex;
    justify-content:center;
    padding: 17px 0px; 
}

.containerTop .button {
    width: 180px;
    text-align: center;
    margin-left: 2%;
}

.button.Two {
    background-color: rgb(72, 163, 248);
}

.ExercisesList .containerBottom {
    display: flex;
    flex-direction: column;
    margin: 30px 2%;
}

.containerBottom .item {
    display: flex;
    flex-direction: row;
    height: 90px;   
    margin-bottom: 12px;
    box-shadow: 1px 1px 6px silver;  
}

.detailsReps {
    flex: 1;
}

.item .detailsName {
    flex: 3;
    color: #555; 
    margin-left: 2%;
}


@media (min-width: 600px) {
    .containerBottom {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 600px) {
    .ExercisesList .containerBottom {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 5%;
        margin: 30px 2%;
    }
    .containerBottom .item {
        display: flex;
        flex-direction: row;
        height: 100px;   
        margin-bottom: 50px;
    }
}